/** **********************************
* AVANTT DISPLAY
************************************** */

/* 400 - regular */
@font-face {
    font-family: 'Avantt';
    src: url('Avantt-Regular.eot');
    src: url('Avantt-Regular.eot?#iefix') format('embedded-opentype'),
        url('Avantt-Regular.woff2') format('woff2'),
        url('Avantt-Regular.woff') format('woff'),
        url('Avantt-Regular.ttf') format('truetype'),
        url('Avantt-Regular.svg#Avantt-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* 500 - medium */
@font-face {
    font-family: 'Avantt';
    src: url('Avantt-Medium.eot');
    src: url('Avantt-Medium.eot?#iefix') format('embedded-opentype'),
        url('Avantt-Medium.woff2') format('woff2'),
        url('Avantt-Medium.woff') format('woff'),
        url('Avantt-Medium.ttf') format('truetype'),
        url('Avantt-Medium.svg#Avantt-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* 600 - semibold */
@font-face {
    font-family: 'Avantt';
    src: url('Avantt-SemiBold.eot');
    src: url('Avantt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Avantt-SemiBold.woff2') format('woff2'),
        url('Avantt-SemiBold.woff') format('woff'),
        url('Avantt-SemiBold.ttf') format('truetype'),
        url('Avantt-SemiBold.svg#Avantt-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* 700 - bold */
@font-face {
    font-family: 'Avantt';
    src: url('Avantt-Bold.eot');
    src: url('Avantt-Bold.eot?#iefix') format('embedded-opentype'),
        url('Avantt-Bold.woff2') format('woff2'),
        url('Avantt-Bold.woff') format('woff'),
        url('Avantt-Bold.ttf') format('truetype'),
        url('Avantt-Bold.svg#Avantt-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* 800 - extrabold */
@font-face {
    font-family: 'Avantt';
    src: url('Avantt-ExtraBold.eot');
    src: url('Avantt-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Avantt-ExtraBold.woff2') format('woff2'),
        url('Avantt-ExtraBold.woff') format('woff'),
        url('Avantt-ExtraBold.ttf') format('truetype'),
        url('Avantt-ExtraBold.svg#Avantt-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


















